/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Logo from '../components/logo/Logo.js';
import { Global } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Cards from '../components/cards/Cards.js';

const HomeClient = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Helmet>
        <title>Faxina Aí</title>
      </Helmet>
      <Global
        styles={{
          'html, body': {
            height: '100%',
            margin: 0,
            padding: 0,
          },
        }}
      />
      <Box
        sx={{
          backgroundImage: `url(${'/images/background/Background1.png'})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          margin: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#19235C',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent={matchesXs ? 'center' : 'space-between'}
          alignItems="center"
          sx={{ marginTop: matchesXs ? '25px' : '50px' }}
        >
          <Grid
            item
            sx={{
              marginLeft: matchesXs ? '0px' : '135px',
              marginBottom: matchesXs ? '10px' : '0px',
            }}
          >
            <Logo style={{ width: '154px' }} />
          </Grid>
          <Grid item sx={{ marginRight: matchesXs ? '0px' : '100px' }}>
            <Stack spacing={1} direction="row">
              <Button
                key="Faxina Ai Profissional"
                variant="text"
                sx={{
                  fontSize: '13px',
                  color: '#FFFFFF',
                  fontFamily: 'Roboto',
                }}
                onClick={() => navigate('/profissional')}
              >
                Faxina Aí Profissional
              </Button>
              <Button
                key="Politica de privacidade"
                variant="text"
                sx={{
                  fontSize: '13px',
                  color: '#FFFFFF',
                  fontFamily: 'Roboto',
                }}
                onClick={() => navigate('/politica-de-privacidade')}
              >
                Politica de privacidade
              </Button>
              <Button
                key="Termos de uso"
                variant="text"
                sx={{
                  fontSize: '13px',
                  color: '#FFFFFF',
                  fontFamily: 'Roboto',
                }}
                onClick={() => navigate('/termos-de-uso')}
              >
                Termos de uso
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent={matchesXs ? 'center' : 'space-around'}
          alignItems="center"
          sx={{ marginTop: '50px' }}
        >
          <Grid item sx={{ marginBottom: matchesXs ? '10px' : '0px' }}>
            <Box
              margin={matchesXs ? 1 : 0}
              sx={{
                backgroundColor: 'rgba(26, 36, 92, 0.8)',
                borderRadius: '4px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '48.83px',
                  fontWeight: 700,
                  color: '#FFFFFF',
                  textAlign: 'center',
                  maxWidth: '510px',
                }}
              >
                SUA CASA LIMPA A UM CLIQUE
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '16px',
                  fontWeight: 400,
                  textAlign: 'center',
                  color: '#FFFFFF',
                  marginTop: '30px',
                  maxWidth: '450px',
                }}
              >
                Encontre as melhores profissionais da limpeza em sua região. Agende a
                limpeza da sua residencia com o Faxina Aí de forma simples, pratica e
                segura.
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '25px',
                  fontWeight: 600,
                  color: '#FFFFFF',
                  textAlign: 'left',
                  marginTop: '30px',
                  marginLeft: '10px',
                }}
              >
                BAIXE O APP AGORA MESMO
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
              >
                <a
                  href={
                    'https://play.google.com/store/apps/details?id=br.com.faxinaai.cliente'
                  }
                  title={'PlayStore Client link'}
                >
                  <img
                    src={`/images/google-play-badge.png`}
                    alt="Google Play"
                    width="149px"
                  />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <img src={`/images/APPCLIENTE.png`} alt="APPCLIENTE" width="100%" />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '120px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Josefin Sans',
            fontSize: '48.83px',
            fontWeight: 700,
            textAlign: 'center',
            color: '#232233',
          }}
        >
          SOBRE O FAXINA AÍ!
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Josefin Sans',
            fontSize: '16px',
            fontWeight: 400,
            textAlign: 'center',
            color: '#6C6C72',
            marginTop: '30px',
            maxWidth: '600px',
          }}
        >
          Somos seu amigo digital, sempre disponível para te ajudar a colocar a casa
          em ordem, conectando você com as melhores profissionais da limpeza da sua
          região
        </Typography>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-around'}
          alignItems={'center'}
          marginTop={'30px'}
        >
          <Grid item xs={12} md={6} container justifyContent={'center'}>
            <img
              src={`/images/android-mockup.png`}
              alt="APPMOCK"
              width="100%"
              style={{ maxWidth: '666px' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction={'column'}
              spacing={2}
              sx={{ maxWidth: '800px' }}
            >
              <Grid item>
                <Cards
                  title={'Confiança'}
                  message={
                    'Contamos com diferentes modalidade de verificação de parceiros e usuários para propocionar uma experiência tranquila para todos.'
                  }
                />
              </Grid>
              <Grid item>
                <Cards
                  title={'Facil de usar'}
                  message={
                    'Totalmente prático e superintuitivo o Faxina Aí foi desenvolvido para te ajudar a colocar a sua casa em ordem.'
                  }
                />
              </Grid>
              <Grid item>
                <Cards
                  title={'Sem Burocracia'}
                  message={
                    'O Faxina Aí é uma revolução na limpeza domiciliar, transformando aquele desgastante processo de encontrar um bom profissional da limpeza em um simples toque na tela do seu smartphone.'
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundSize: 'cover',
          marginTop: '100px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#232233',
        }}
      >
        <Grid
          container
          direction={matchesXs ? 'column' : 'row'}
          justifyContent="space-evenly"
          alignItems="center"
          marginTop={matchesXs ? '20px' : '80px'}
          marginBottom={'100px'}
        >
          <Grid item marginTop={matchesXs ? '80px' : '0px'}>
            <Logo style={{ width: '154px' }} />
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                textAlign: 'center',
                maxWidth: '334px',
                color: '#FFFFFF',
                marginTop: '10px',
              }}
            >
              Somos seu amigo digital, sempre disponivel para te ajudar a colocar a
              casa em ordem, conectando você com as melhores profissionais da limpeza
              da sua região.
            </Typography>
            <Stack
              spacing={3}
              direction="row"
              style={{ marginTop: '20px' }}
              justifyContent="center"
            >
              <a href={'https://www.instagram.com/faxina.ai'} title={'Instagram'}>
                <img
                  src={`/images/social-midias/instagram.png`}
                  alt="Instagram"
                  width="24px"
                />
              </a>
              <a
                href={'https://www.facebook.com/profile.php?id=61566916970774'}
                title={'Facebook'}
              >
                <img
                  src={`/images/social-midias/facebook.png`}
                  alt="Facebook"
                  width="24px"
                />
              </a>
              <a
                href={'https://www.youtube.com/channel/UCb6gBRGsLT_kxlAHKML05FQ'}
                title={'Youtube'}
              >
                <img
                  src={`/images/social-midias/youtube.png`}
                  alt="Youtube"
                  width="24px"
                />
              </a>
              <a
                href={'https://www.linkedin.com/company/faxina-ai'}
                title={'Linkedin'}
              >
                <img
                  src={`/images/social-midias/linkedin.png`}
                  alt="Linkedin"
                  width="24px"
                />
              </a>
            </Stack>
          </Grid>
          <Grid item marginTop={matchesXs ? '80px' : '0px'}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '25px',
                fontWeight: 600,
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Acesso rapido
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: 400,
                maxWidth: '334px',
                color: '#FFFFFF',
              }}
            >
              Conheça o Faxina Aí!
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: 400,
                maxWidth: '334px',
                color: '#FFFFFF',
              }}
            >
              <a
                onClick={() => navigate('/politica-de-privacidade')}
                style={{ color: '#5169DF', textDecoration: 'none' }}
              >
                Politica de privacidade
              </a>
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                maxWidth: '334px',
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              <a
                onClick={() => navigate('termos-de-uso')}
                style={{ color: '#5169DF', textDecoration: 'none' }}
              >
                Termos de uso
              </a>
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                maxWidth: '334px',
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Fale Conosco:{' '}
              <a
                href="mailto:contato@faxinaai.com.br"
                style={{ color: '#5169DF', textDecoration: 'underline' }}
              >
                contato@faxinaai.com.br
              </a>
            </Typography>
          </Grid>
          <Grid item marginTop={matchesXs ? '80px' : '0px'}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '25px',
                fontWeight: 600,
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Baixe agora mesmo
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                textAlign: 'center',
                maxWidth: '334px',
                color: '#FFFFFF',
                marginTop: '10px',
              }}
            >
              Disponivel apenas nas lojas do google play. Embreve disponível na
              Appstore
            </Typography>
            <a
              href={
                'https://play.google.com/store/apps/details?id=br.com.faxinaai.cliente'
              }
              title={'PlayStore Client link'}
            >
              <img
                src={`/images/google-play-badge.png`}
                alt="Google Play"
                width="149px"
                style={{ marginTop: '10px' }}
              />
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HomeClient;
