/* eslint-disable jsx-a11y/iframe-has-title */
import { Helmet } from 'react-helmet';
import React from 'react';
import { Global } from '@emotion/react';

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title>Política de Privacidade</title>
      </Helmet>
      <Global
        styles={{
          'html, body': {
            height: '100%',
            margin: 0,
            padding: 0,
            overflow: 'hidden',
            position: 'relative',
          },
          iframe: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          },
        }}
      />
      <iframe src="https://termos-faxina.s3.sa-east-1.amazonaws.com/privacidade.pdf"></iframe>
    </>
  );
};

export default Privacy;
