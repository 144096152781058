/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from 'react-helmet';
import React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Global } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import Cards from '../components/cards/Cards.js';
import LogoProfissional from '../components/logo/LogoProfissional.js';

const HomeCleaner = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Helmet>
        <title>Faxina Aí Profissional</title>
      </Helmet>
      <Global
        styles={{
          'html, body': {
            height: '100%',
            margin: 0,
            padding: 0,
          },
        }}
      />
      <Box
        sx={{
          backgroundImage: `url(${'/images/background/Background2.png'})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          margin: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent={matchesXs ? 'center' : 'space-between'}
          alignItems="center"
          sx={{ marginTop: matchesXs ? '25px' : '50px' }}
        >
          <Grid
            item
            sx={{
              marginLeft: matchesXs ? '0px' : '135px',
              marginBottom: matchesXs ? '10px' : '0px',
            }}
          >
            <LogoProfissional style={{ width: '300px' }} />
          </Grid>
          <Grid item sx={{ marginRight: matchesXs ? '0px' : '100px' }}>
            <Stack spacing={1} direction="row">
              <Button
                key="Faxina Ai"
                variant="text"
                sx={{
                  fontSize: '13px',
                  color: '#FFFFFF',
                  fontFamily: 'Roboto',
                }}
                onClick={() => navigate('/')}
              >
                Faxina Aí
              </Button>
              <Button
                key="Politica de privacidade"
                variant="text"
                sx={{
                  fontSize: '13px',
                  color: '#FFFFFF',
                  fontFamily: 'Roboto',
                }}
                onClick={() => navigate('/politica-de-privacidade')}
              >
                Politica de privacidade
              </Button>
              <Button
                key="Termos de uso"
                variant="text"
                sx={{
                  fontSize: '13px',
                  color: '#FFFFFF',
                  fontFamily: 'Roboto',
                }}
                onClick={() => navigate('/termos-de-uso')}
              >
                Termos de uso
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent={matchesXs ? 'center' : 'space-around'}
          alignItems="center"
          sx={{ marginTop: '50px' }}
        >
          <Grid item sx={{ marginBottom: matchesXs ? '10px' : '0px' }}>
            <Box
              margin={matchesXs ? 1 : 0}
              sx={{
                backgroundColor: 'rgba(80, 105, 223, 0.8)',
                borderRadius: '4px',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '48.83px',
                  fontWeight: 700,
                  color: '#FFFFFF',
                  textAlign: 'center',
                  maxWidth: '510px',
                }}
              >
                Seu serviço valorizado, sua agenda no controle!
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#FFFFFF',
                  textAlign: 'center',
                  marginTop: '30px',
                  maxWidth: '450px',
                }}
              >
                O Faxina Aí Profissional é uma plataforma inovadora que conecta quem
                precisa de faxina com quem faz a diferença.
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Josefin Sans',
                  fontSize: '25px',
                  fontWeight: 600,
                  color: '#FFFFFF',
                  textAlign: 'left',
                  marginTop: '30px',
                  marginLeft: '10px',
                }}
              >
                BAIXE O APP AGORA MESMO
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  marginTop: '10px',
                  marginLeft: '10px',
                }}
              >
                <a
                  href={
                    'https://play.google.com/store/apps/details?id=br.com.faxinaai.profissional'
                  }
                  title={'PlayStore Client link'}
                >
                  <img
                    src={`/images/google-play-badge.png`}
                    alt="Google Play"
                    width="149px"
                  />
                </a>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <img
              src={`/images/APPFAXINEIRA.png`}
              alt="APPFAXINEIRA"
              width="100%"
              style={{
                maxWidth: '646px',
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          marginTop: '120px',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Josefin Sans',
            fontSize: '48.83px',
            fontWeight: 700,
            textAlign: 'center',
            color: '#232233',
          }}
        >
          SOBRE O FAXINA AÍ PROFISSIONAL!
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Josefin Sans',
            fontSize: '16px',
            fontWeight: 400,
            textAlign: 'center',
            color: '#6C6C72',
            marginTop: '30px',
            maxWidth: '1000px',
          }}
        >
          O Faxina Aí Profissional é uma plataforma inovadora que conecta prestadoras
          de serviço de limpeza com clientes de forma rápida e segura. Nosso objetivo
          é facilitar a vida das profissionais da limpeza, oferecendo mais
          oportunidades e garantindo que elas encontrem clientes de forma simples e
          eficiente. Além de ser fácil de usar, o aplicativo proporciona segurança
          para ambas as partes, garantindo um serviço de qualidade e uma experiência
          satisfatória.
        </Typography>
        <Grid
          container
          direction={'row'}
          justifyContent={'space-around'}
          alignItems={'center'}
          marginTop={'30px'}
        >
          <Grid item xs={12} md={6} container justifyContent={'center'}>
            <img
              src={`/images/android-mockup.png`}
              alt="APPMOCK"
              width="100%"
              style={{ maxWidth: '666px' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid
              container
              direction={'column'}
              spacing={2}
              sx={{ maxWidth: '800px' }}
            >
              <Grid item>
                <Cards
                  title={'Suporte Dedicado para Prestadoras'}
                  message={
                    'Oferecemos suporte especializado e dedicado às profissionais de limpeza, garantindo que todas as suas dúvidas e necessidades sejam rapidamente atendidas, para que você se concentre no que faz de melhor.'
                  }
                />
              </Grid>
              <Grid item>
                <Cards
                  title={'Visibilidade e Oportunidades'}
                  message={
                    'Ao se cadastrar no Faxina Aí, você terá a chance de expandir seu alcance, ganhando visibilidade e recebendo mais ofertas e seus rendimentos.'
                  }
                />
              </Grid>
              <Grid item>
                <Cards
                  title={'Personalização do Perfil'}
                  message={
                    'No nosso aplicativo, você pode criar um perfil completo com fotos, descrição dos serviços e feedbacks de clientes, aumentando suas chances de ser escolhida e de se destacar entre as demais profissionais.'
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          backgroundSize: 'cover',
          marginTop: '100px',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#232233',
        }}
      >
        <Grid
          container
          direction={matchesXs ? 'column' : 'row'}
          justifyContent="space-evenly"
          alignItems="center"
          marginTop={matchesXs ? '20px' : '80px'}
          marginBottom={'100px'}
        >
          <Grid item marginTop={matchesXs ? '80px' : '0px'}>
            <LogoProfissional style={{ width: '300px' }} />
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                textAlign: 'center',
                maxWidth: '334px',
                color: '#FFFFFF',
                marginTop: '10px',
              }}
            >
              Somos o seu amigo digital, sempre ao seu lado para te ajudar a
              conquistar mais oportunidades. No Faxina Aí Profissional, conectamos
              você a clientes na sua região de forma rápida, segura e prática, para
              que você possa focar no que faz de melhor: deixar tudo brilhando!
            </Typography>
            <Stack
              spacing={3}
              direction="row"
              style={{ marginTop: '20px' }}
              justifyContent="center"
            >
              <a href={'https://www.instagram.com/faxina.ai'} title={'Instagram'}>
                <img
                  src={`/images/social-midias/instagram.png`}
                  alt="Instagram"
                  width="24px"
                />
              </a>
              <a
                href={'https://www.facebook.com/profile.php?id=61566916970774'}
                title={'Facebook'}
              >
                <img
                  src={`/images/social-midias/facebook.png`}
                  alt="Facebook"
                  width="24px"
                />
              </a>
              <a
                href={'https://www.youtube.com/channel/UCb6gBRGsLT_kxlAHKML05FQ'}
                title={'Youtube'}
              >
                <img
                  src={`/images/social-midias/youtube.png`}
                  alt="Youtube"
                  width="24px"
                />
              </a>
              <a
                href={'https://www.linkedin.com/company/faxina-ai'}
                title={'Linkedin'}
              >
                <img
                  src={`/images/social-midias/linkedin.png`}
                  alt="Linkedin"
                  width="24px"
                />
              </a>
            </Stack>
          </Grid>
          <Grid item marginTop={matchesXs ? '80px' : '0px'}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '25px',
                fontWeight: 600,
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Acesso rapido
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: 400,
                maxWidth: '334px',
                color: '#FFFFFF',
              }}
            >
              Conheça o Faxina Aí Profissional!
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: 400,
                maxWidth: '334px',
                color: '#FFFFFF',
              }}
            >
              <a
                onClick={() => navigate('/politica-de-privacidade')}
                style={{ color: '#5169DF', textDecoration: 'none' }}
              >
                Politica de privacidade
              </a>
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                maxWidth: '334px',
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              <a
                onClick={() => navigate('termos-de-uso')}
                style={{ color: '#5169DF', textDecoration: 'none' }}
              >
                Termos de uso
              </a>
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                maxWidth: '334px',
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Fale Conosco:{' '}
              <a
                href="mailto:contato@faxinaai.com.br"
                style={{ color: '#5169DF', textDecoration: 'underline' }}
              >
                contato@faxinaai.com.br
              </a>
            </Typography>
          </Grid>
          <Grid item marginTop={matchesXs ? '80px' : '0px'}>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '25px',
                fontWeight: 600,
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Baixe agora mesmo
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontWeight: 400,
                textAlign: 'center',
                maxWidth: '334px',
                color: '#FFFFFF',
                marginTop: '10px',
              }}
            >
              Disponivel apenas nas lojas do google play. Embreve disponível na
              Appstore
            </Typography>
            <a
              href={
                'https://play.google.com/store/apps/details?id=br.com.faxinaai.profissional'
              }
              title={'PlayStore Client link'}
            >
              <img
                src={`/images/google-play-badge.png`}
                alt="Google Play"
                width="149px"
                style={{ marginTop: '10px' }}
              />
            </a>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HomeCleaner;
