import 'react-perfect-scrollbar/dist/css/styles.css';
import AppRoutes from './AppRoutes';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';

const App = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;
