import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeClient from './pages/HomeClient';
import HomeCleaner from './pages/HomeCleaner';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';

function AppRoutes() {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<HomeClient />} />
        <Route path="/profissional" element={<HomeCleaner />} />
        <Route path="/termos-de-uso" element={<Terms />} />
        <Route path="/politica-de-privacidade" element={<Privacy />} />
      </Route>
    </Routes>
  );
}
export default AppRoutes;
