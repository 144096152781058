import React from 'react';
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PropTypes from 'prop-types';

const Cards = (props) => {
  const { title, message } = props;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Grid
          container
          direction={'row'}
          spacing={1}
          marginTop={'10px'}
          marginLeft={'10px'}
        >
          <Grid item>
            <CheckCircleRoundedIcon color="primary" />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontFamily: 'Josefin Sans',
                fontSize: '20px',
                fontWeight: 600,
                color: '#232233',
              }}
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontFamily: 'Josefin Sans',
            fontSize: '16px',
            fontWeight: 400,
            color: '#232233',
            marginLeft: '20px',
            marginBottom: '20px',
          }}
        >
          {message}
        </Typography>
      </Box>
    </>
  );
};

Cards.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Cards;
